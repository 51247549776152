<template>
  <div :class="{ 'mb-4': value.length > 0 }">
    <b-input
      type="password"
      autocomplete="new-password"
      :password-reveal="value.length > 0"
      :value="value"
      @input="emitChanges"
    ></b-input>

    <div class="content set-password" v-if="value.length > 0">
      <ul class="has-text-grey-icon is-size-7 has-text-weight-medium">
        <li v-if="policy.length" :class="{ 'password--correct': isLengthy }">
          {{
            $t("af:password_check.policy.length.label", {
              length: policy.length,
            })
          }}
          <b-icon
            v-if="isLengthy"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li
          v-if="policy.upperLower"
          :class="{ 'password--correct': hasUpperLower }"
        >
          {{ $t("af:password_check.policy.upper_lower.label") }}
          <b-icon
            v-if="hasUpperLower"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li v-if="policy.number" :class="{ 'password--correct': hasDigit }">
          {{ $t("af:password_check.policy.number.label") }}
          <b-icon
            v-if="hasDigit"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li
          v-if="policy.specialCharacter"
          :class="{ 'password--correct': hasSpecial }"
        >
          {{ $t("af:password_check.policy.special.label") }}
          <b-icon
            v-if="hasSpecial"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { passwordStrength } from "check-password-strength";

export default {
  name: "PasswordCheckerInput",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      policy: {
        upperLower: true,
        number: true,
        specialCharacter: false,
        length: 8,
      },
      passwordCandidate: "",
    };
  },
  computed: {
    result() {
      return passwordStrength(this.passwordCandidate);
    },
    hasUpperLower() {
      return (
        this.result.contains.includes("lowercase") &&
        this.result.contains.includes("uppercase")
      );
    },
    hasDigit() {
      return this.result.contains.includes("number");
    },
    hasSpecial() {
      return this.passwordCandidate.match(
        /[!"#$%&'()*+,\-./:;<=>?@\[\]^_`{|}~]/
      );
    },
    isLengthy() {
      return this.result.length >= this.policy.length;
    },
    isValid() {
      if (this.policy.upperLower && !this.hasUpperLower) return false;
      if (this.policy.number && !this.hasDigit) return false;
      if (this.policy.specialCharacter && !this.hasSpecial) return false;
      if (!this.isLengthy) return false;

      return true;
    },
  },
  // async created() {
  //   await this.$store.dispatch("passwordPolicy/fetch");
  //   this.policy = this.$store.getters["passwordPolicy/get"];
  // },
  methods: {
    emitChanges(value) {
      this.passwordCandidate = value;

      this.$emit("input", value);
      this.$emit("validated", this.isValid);
    },
  },
};
</script>

<style>
::-ms-reveal {
  display: none;
}
</style>

<style scoped lang="scss">
.password {
  &--correct {
    color: #00b67f;

    span {
      color: inherit !important;
    }
  }
}
</style>
